<template>
  <div v-if="subscriptionData">
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">Alert</h4>
      <div class="alert-body">
        <ul v-for="(values, index) in errors_back" :key="index">
          <li v-for="(value, index) in values" :key="index">{{ value }}</li>
        </ul>
      </div>
    </b-alert>
    <b-row class="row-eq-height">
      <b-col class="mb-1" cols="12" md="6">
        <b-card title="Subscriptions" class="h-100 d-flex flex-wrap">
          <table class="table table-bordered">
            <tbody>
              <tr>
                <th class="text-left">ID</th>
                <td class="text-right">{{ subscriptionData.id }}</td>
              </tr>
              <tr>
                <th class="text-left">Code</th>
                <td class="text-right">{{ subscriptionData.code }}</td>
              </tr>
              <tr>
                <th class="text-left">Ads Limit</th>
                <td class="text-right" v-if="subscriptionData.ads_limit">
                  {{ subscriptionData.ads_limit }}
                </td>
              </tr>
              <tr>
                <th class="text-left">Status</th>
                <td class="text-right">
                  <b-badge variant="primary">
                    {{ subscriptionData.subscription_status }}
                  </b-badge>
                </td>
              </tr>

              <tr>
                <th class="text-left">Date</th>
                <td class="text-right">
                  {{ subscriptionData.created_at | formatDate }}
                </td>
              </tr>
              <tr v-if="delUser">
                <th class="text-left">Last Update Date</th>
                <td class="text-right">
                  {{ subscriptionData.updated_at | formatDate }}
                </td>
              </tr>
              <tr v-else>
                <th class="text-left">Deleted Date</th>
                <td class="text-right">
                  {{ subscriptionData.deleted_at | formatDate }}
                </td>
              </tr>

              <tr>
                <th class="text-left">Transaction ref</th>
                <td class="text-right" dir="rtl">
                  {{
                    subscriptionData.transactions.length > 0
                      ? subscriptionData.transactions[0].transaction_ref
                      : ''
                  }}
                </td>
              </tr>

              <tr v-if="subscriptionData.cart != null">
                <th class="text-left">payment gateway</th>
                <td class="text-right" dir="rtl">
                  <b-badge class="badge btn-primary">
                    {{ subscriptionData.cart.payment_gateway_type }}</b-badge
                  >
                </td>
              </tr>
              <tr v-if="subscriptionData.success_date != null">
                <th class="text-left">Success Date</th>
                <td class="text-right" dir="ltr">
                  {{ subscriptionData.success_date }}
                </td>
              </tr>
              <tr v-if="subscriptionData.delivered_date != null">
                <th class="text-left">Delivered Date</th>
                <td class="text-right" dir="ltr">
                  {{ subscriptionData.delivered_date }}
                </td>
              </tr>
              <tr v-if="subscriptionData.completed_date != null">
                <th class="text-left">Completed Date</th>
                <td class="text-right" dir="ltr">
                  {{ subscriptionData.completed_date }}
                </td>
              </tr>
            </tbody>
          </table>
        </b-card>
      </b-col>
      <b-col md="6" class="mb-1">
        <b-card class="h-100 d-flex flex-wrap" footer-tag="footer">
          <b-button
            block
            :to="'/subscriptions/show/invoice/' + subscriptionData.id"
            variant="outline-primary "
            v-if="
              authPermissions.includes('show-subscription-invoice') &&
              subscriptionData.transactions.length != 0
            "
            class="mt-1"
          >
            Invoice
          </b-button>
          <div v-if="delUser" class="d-flex flex-wrap justify-content-center">
            <b-button
              block
              v-if="authPermissions.includes('delete')"
              @click="deleteSubscription(subscriptionData.id)"
              variant="outline-danger"
              class="mt-1"
            >
              Delete
            </b-button>

            <b-button
              block
              v-if="
                authPermissions.includes('show-eContract') &&
                subscriptionData.is_portal == false &&
                subscriptionData.subscription_status == 'delivered'
              "
              :to="{
                path: '/orders/show/' + subscriptionData.id + '/e-contract',
              }"
              variant="primary"
              class="mt-1"
            >
              Show E-Contract
            </b-button>
          </div>

          <hr />
          <div v-if="delUser" class="d-flex">
            <div class="m-4 w-50">
              <h6 class="text-muted font-weight-bolder">Created at</h6>
              <h3 class="mb-0">
                {{ subscriptionData.created_at | formatDate }}
              </h3>
            </div>
            <div class="m-4 w-50">
              <h6 class="text-muted font-weight-bolder">Updated at</h6>
              <h3 class="mb-0">
                {{ subscriptionData.updated_at | formatDate }}
              </h3>
            </div>
          </div>
          <div
            v-else
            class="d-flex justify-content-md-center align-items-center text-center"
          >
            <div class="m-4">
              <h6 class="text-muted font-weight-bolder">Created at</h6>
              <h3 class="mb-0">
                {{ subscriptionData.created_at | formatDate }}
              </h3>
            </div>
            <div class="m-4">
              <h6 class="text-muted font-weight-bolder">Deleted at</h6>
              <h3 class="mb-0">
                {{ subscriptionData.deleted_at | formatDate }}
              </h3>
            </div>
          </div>
          <template #footer v-if="authPermissions.includes('create_refund')">
            <em>
              <b-row>
                <b-col cols="12" v-if="subscriptionData.refund_id != null">
                  <b-button
                    block
                    variant="outline-warning"
                    @click="
                      $router.push(
                        '/orders/refund/show/' + subscriptionData.refund_id
                      )
                    "
                  >
                    Show Refund Data
                  </b-button>
                </b-col>
              </b-row>
            </em>
          </template>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <!-- User Info: Left col -->
      <b-col cols="12" md="6" class="mb-1">
        <b-card
          v-if="subscriptionData.package_snapshot != null"
          title="Package"
          class="h-100 cardH d-flex flex-wrap table-responsive"
        >
          <!-- User Avatar & Action Buttons -->
          <table class="table table-bordered">
            <tbody>
              <tr>
                <th class="text-left">ID</th>
                <td class="text-right">
                  {{ JSON.parse(subscriptionData.package_snapshot).id }}
                </td>
              </tr>
              <tr>
                <th class="text-left">Name</th>
                <td class="text-right">
                  {{
                    JSON.parse(
                      JSON.parse(subscriptionData.package_snapshot).name
                    ).name_en
                  }}
                </td>
              </tr>
              <tr>
                <th class="text-left">ads_limit</th>
                <td class="text-right">
                  {{ JSON.parse(subscriptionData.package_snapshot).ads_limit }}
                </td>
              </tr>
              <tr>
                <th class="text-left">code</th>
                <td class="text-right">
                  {{ JSON.parse(subscriptionData.package_snapshot).code }}
                </td>
              </tr>
              <tr>
                <th class="text-left">is_active</th>
                <td class="text-right">
                  {{ JSON.parse(subscriptionData.package_snapshot).is_active }}
                </td>
              </tr>
              <tr>
                <th class="text-left">price</th>
                <td class="text-right">
                  {{ JSON.parse(subscriptionData.package_snapshot).price }}
                </td>
              </tr>
            </tbody>
          </table>
        </b-card>
        <b-card
          v-else
          title="Vendor"
          class="h-100 cardH text-center d-flex flex-wrap"
          style="display: flex; justify-content: center; align-items: center"
        >
          <h1>Mobile Masr</h1>
        </b-card>
      </b-col>
      <b-col class="mb-1" cols="12" md="6">
        <b-card title="User" class="h-100 d-flex flex-wrap table-responsive">
          <table class="table table-bordered">
            <tbody>
              <tr>
                <th class="text-left">ID</th>
                <td class="text-right">{{ subscriptionData.user.id }}</td>
              </tr>
              <tr>
                <th class="text-left">First Name</th>
                <td class="text-right">
                  {{ subscriptionData.user.firstname }}
                </td>
              </tr>
              <tr>
                <th class="text-left">Last Name</th>
                <td class="text-right">{{ subscriptionData.user.lastname }}</td>
              </tr>
              <tr>
                <th class="text-left">Email</th>
                <td class="text-right">{{ subscriptionData.user.email }}</td>
              </tr>
              <tr>
                <th class="text-left">Phone 1</th>

                <td class="text-right">
                  <a :href="'tel:' + subscriptionData.phoneone" class="h4">
                    <the-mask
                      mask="#### ### ####"
                      disabled
                      v-model="subscriptionData.phoneone"
                      class="border-0 text-right phonenumber"
                    >
                    </the-mask>
                  </a>
                </td>
              </tr>
              <tr>
                <th class="text-left">Phone 2</th>

                <td class="text-right">
                  <a :href="'tel:' + subscriptionData.phonetwo" class="h4">
                    <the-mask
                      mask="#### ### ####"
                      disabled
                      v-model="subscriptionData.phonetwo"
                      class="border-0 text-right phonenumber"
                    >
                    </the-mask>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </b-card>
      </b-col>
      <b-col class="mb-1" md="12">
        <b-row>
          <!-- EContract Data -->
          <b-col class="mb-1" md="6">
            <b-card
              title="Payment Provider"
              class="h-100 d-flex flex-wrap table-responsive"
            >
              <table class="table table-bordered">
                <tbody>
                  <tr>
                    <th class="text-left">Name</th>
                    <td
                      class="text-right"
                      v-if="subscriptionData.payment_provider"
                    >
                      {{ subscriptionData.payment_provider.name }}
                    </td>
                  </tr>
                  <tr>
                    <th class="text-left">active</th>
                    <td
                      class="text-right"
                      v-if="subscriptionData.payment_provider"
                    >
                      {{ subscriptionData.payment_provider.active }}
                    </td>
                  </tr>
                  <tr>
                    <th class="text-left">Fees</th>
                    <td
                      class="text-right"
                      v-if="subscriptionData.payment_provider"
                    >
                      {{ subscriptionData.payment_provider.provider_fees }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </b-card>
          </b-col>
          <b-col class="mb-1" md="6">
            <b-card
              title="Variants"
              class="h-100 d-flex flex-wrap table-responsive"
            >
              <b-table
                ref="admint"
                id="AdminsTable"
                striped
                hover
                responsive
                show-empty
                class="position-relative"
                :items="variants"
                :fields="variantsFields"
              >
                <template #cell(quantity)="data">
                  {{ data.value }}
                </template>

                <template #cell(updated_at)="data">
                  {{ data.value | formatDate }}
                </template>

                <!-- admin actions -->
                <template #cell(actions)="items">
                  <div>
                    <!-- Show Order -->
                    <b-button
                      style="
                        padding: 7px;
                        margin: 6px;
                        width: 35px;
                        height: 35px;
                      "
                      v-if="authPermissions.includes('show')"
                      size="sm"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="success"
                      type="submit"
                      :to="{
                        path:
                          '/' +
                          (items.item.is_new ? 'New' : 'Used') +
                          '/ShowProduct/variant/show/' +
                          items.item.id,
                      }"
                    >
                      <feather-icon
                        style="width: 18px; height: 18px"
                        icon="EyeIcon"
                      />
                    </b-button>
                  </div>
                </template>
                <template #cell(is_portal)="data">
                  <b-badge variant="info" v-if="data.value"> Portal </b-badge>
                  <b-badge variant="info" v-else> In Store </b-badge>
                </template>
                <template #cell(status)="data">
                  <b-badge
                    :variant="
                      data.value == 'enabled'
                        ? 'light-success'
                        : data.value == 'sold_out'
                        ? 'light-secondary'
                        : 'light-warning'
                    "
                  >
                    {{ data.value }}
                  </b-badge>
                </template>
                <template #cell(seller_price)="data">
                  {{ data.item.sale_price - data.item.profit }}
                </template>
              </b-table>
            </b-card>
          </b-col>

          <b-col md="12" v-if="subscriptionData.transactions.length > 0">
            <b-card>
              <p class="text-secondary h4 mt-1">Transactions</p>
              <table class="table table-striped table-responsive">
                <thead>
                  <tr>
                    <th scope="col">TRANSACTION REF</th>
                    <th scope="col">TRANSACTION TYPE</th>
                    <th scope="col">STATUS</th>
                    <th scope="col">AMOUNT</th>
                    <th scope="col">REMAINING AMOUNT</th>
                    <th scope="col">ACTION</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(
                      transaction, index
                    ) in subscriptionData.transactions"
                    :key="index"
                  >
                    <td dir="rtl">{{ transaction.transaction_ref }}</td>
                    <td dir="rtl">{{ transaction.transaction_type }}</td>
                    <td dir="rtl">{{ transaction.status }}</td>
                    <td dir="rtl">{{ transaction.amount }}</td>
                    <td dir="rtl">{{ transaction.remaining_amount }}</td>
                    <td>
                      <b-button
                        style="
                          padding: 7px;
                          margin: 6px;
                          width: 35px;
                          height: 35px;
                        "
                        class="btn-icon rounded-circle"
                        :to="{ path: '/payment/show/' + transaction.id }"
                        variant="success"
                      >
                        <feather-icon
                          style="width: 18px; height: 18px"
                          icon="EyeIcon"
                        />
                      </b-button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <!-- AWB Logger Data -->
    <b-card
      v-if="shipData != null"
      title="Airway Bill Status "
      class="d-flex flex-wrap"
    >
      <b-row>
        <b-col cols="12">
          <app-timeline>
            <app-timeline-item
              v-for="(item, index) in shipData.TrackingLogDetails"
              :key="index"
              variant="primary"
            >
              <div class="">
                <h5>{{ item.Remarks }}</h5>
                <p>{{ item.ActivityDate }}</p>
              </div>
            </app-timeline-item>
          </app-timeline>
        </b-col>
      </b-row>
    </b-card>
    <!-- Order Logger -->
    <b-card title="Notes" class="d-flex flex-wrap">
      <b-row>
        <b-col cols="12">
          <label for="textarea-default">Note</label>
          <b-form-textarea
            id="textarea-default"
            v-model="loggerNote.note"
            placeholder="Note"
            rows="3"
          />
          <b-button
            variant="primary"
            class="mt-1"
            @click="storeLogger(subscriptionData.id)"
          >
            Submit
          </b-button>
        </b-col>
        <b-col class="mt-3" cols="12">
          <app-timeline>
            <app-timeline-item
              v-for="(item, index) in subscriptionData.loggers"
              :key="index"
              :variant="item.admin != null ? 'primary' : 'success'"
            >
              <div
                class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"
              >
                <h6>{{ item.note }}</h6>
              </div>
              <b-media v-if="item.admin != null">
                <template #aside>
                  <b-avatar :src="item.admin.profile_picture" />
                </template>
                <h6>{{ item.admin.firstname + ' ' + item.admin.lastname }}</h6>
                <p>{{ item.admin.role }}</p>
              </b-media>
              <b-media v-else>
                <template #aside>
                  <!-- <b-avatar :src="item.vendor.profile_picture" /> -->
                </template>
                <!-- <h6>{{item.vendor.storename}}</h6> -->
              </b-media>
            </app-timeline-item>
          </app-timeline>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
  
  <script>
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import { TheMask } from 'vue-the-mask'
import { required } from '@validations'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

export default {
  metaInfo: {
    meta: [
      { charset: 'utf-8' },
      {
        name: 'viewport',
        content:
          'width=device-width,initial-scale=1.0, maximum-scale=2.0, user-scalable=yes',
      },
    ],
  },
  components: {
    AppTimeline,
    AppTimelineItem,
    TheMask,
    AppCollapse,
    AppCollapseItem,
  },

  data() {
    return {
      required,
      authPermissions: [],
      subscriptionData: null,
      errors_back: [],
      storeAttachmentErrorsBack: [],
      storeAttachmentShowDismissibleAlert: false,
      showDismissibleAlert: false,
      id: 0,
      status: '',
      area: '',
      delUser: true,
      imgIndex: null,
      profileArr: [],

      loggerNote: {
        note: '',
      },
      discount: '',

      compareItems: [],
      compareFields: [
        {
          key: 'name',
          label: 'name',
        },
        {
          key: 'selling',
          label: 'selling',
        },
        {
          key: 'buying',
          label: 'buying',
        },
        {
          key: 'identical',
          label: 'identical',
        },
      ],
      fields: [
        {
          key: 'name',
          label: 'Product',
        },
        {
          key: 'sku',
          label: 'SKU',
        },
        {
          key: 'quantity',
          label: 'Qty',
        },
        {
          key: 'vendor_price',
          label: 'Cost',
        },
        {
          key: 'margin',
          label: 'margin',
        },
        {
          key: 'price',
          label: 'Price',
        },

        {
          key: 'total',
          label: 'Total',
        },

        {
          key: 'item_status_slug',
          label: 'Item Status In Order',
        },
        {
          key: 'is_new',
          label: 'Type',
        },
        // {
        //   key: 'identical',
        //   label: 'identical'
        // },
        // {
        //   key: 'CompareReports',
        //   label: 'Compare Reports'
        // }
        // },
        {
          key: 'show',
          label: 'show',
        },
      ],

      variantsFields: [
        {
          key: 'id',
          label: 'Id',
          sortable: true,
        },

        {
          key: 'status',
          label: 'Status',
        },
        {
          key: 'is_portal',
          label: 'Sales Channel',
        },
        {
          key: 'sku',
          label: 'Variant SKU',
        },
        {
          key: 'seller_price',
          label: 'Seller Price',
        },
        {
          key: 'sale_price',
          label: 'Sale Price',
        },
        // {
        //   key: 'created_at',
        //   label: 'Created_At',
        // },
        {
          key: 'updated_at',
          label: 'Updated_At',
        },
        'actions',
      ],
      orderAttachmentFields: [
        {
          key: 'type',
          label: 'Attachment Type',
        },
        // {
        //   key: 'name',
        //   label: 'File',
        // },

        {
          key: 'actions',
          label: 'Actions',
        },
      ],
      // table fildes
      fieldsTransactions: [
        {
          key: 'id',
          label: 'Id',
          sortable: true,
        },
        {
          key: 'status',
          label: 'status',
        },
        {
          key: 'customer_name',
          label: 'customer name',
        },

        {
          key: 'customer_data',
          label: 'customer (email/phone)',
        },

        {
          key: 'transaction_type',
          label: 'transaction type',
        },
        {
          key: 'payment_method',
          label: 'payment method',
        },
        {
          key: 'amount',
          label: 'amount',
        },
        {
          key: 'remaining_amount',
          label: 'remaining amount',
        },
        {
          key: 'payment_commission',
          label: 'payment commission',
        },
        {
          key: 'transaction_fees',
          label: 'transaction fees',
        },
        {
          key: 'transaction_ref',
          label: 'transaction ref',
        },
        {
          key: 'created_at',
          label: 'created at',
        },
        {
          key: 'updated_at',
          label: 'updated at',
        },
        {
          key: 'actions',
        },
      ],
      itemsTransactions: [],
      identicalType: false,
      shipData: null,
      shipPDF: null,
      cashStatusOptions: ['delivered', 'canceled'],
      orderFileAttachmentOptions: [
        'invoice',
        'agreement',
        'airwaybill',
        'signed_airwaybill',
        'refund_airwaybill',
      ],
      cashStatusSelect: null,
      customerData: {
        customer_name: null,
        customer_id_number: null,
        id_front_image: null,
        id_back_image: null,
        id_front_image_file: null,
        id_back_image_file: null,
      },
      enableUpdateCustomerData: false,
      enableUpdateIdFrontImage: false,
      enableUpdateIdBackImage: false,
      orderAttachmentData: {
        file: null,
        type: null,
        note: null,
      },
      variants: [],
    }
  },

  created() {
    this.id = this.$route.params.id
    this.authPermissions = this.$store.state.authPermissions
    this.fetchData()
  },
  methods: {
    getBillData() {
      axios
        .get(
          'subscriptions/' +
            this.subscriptionData.air_way_bill.air_way_bill_number +
            '/tracking/get'
        )
        .then((result) => {
          this.shipData = result.data.data
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    showModal() {
      this.$refs['my-modal'].show()
    },
    async getPDF() {
      await axios
        .get(
          'orders/awb/' +
            this.subscriptionData.air_way_bill.air_way_bill_number +
            '/pdf/generate'
        )
        .then((result) => {
          this.shipPDF =
            'data:application/pdf;base64,' + result.data.data.ReportDoc
          this.showModal()
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },

    validateOrderAttachmentData() {
      if (this.orderAttachmentData.file == null) {
        this.showDismissibleAlert = true
        this.errors_back.push({
          error: 'must upload attachment file ',
        })
        return
      }
      if (this.orderAttachmentData.type == null) {
        this.showDismissibleAlert = true
        this.errors_back.push({
          error: 'invalid selected type ',
        })
        return
      }
    },

    fetchData() {
      axios
        .get('subscriptions/' + this.id)
        .then((result) => {
          this.subscriptionData = result.data.data
          // //console.log(this.subscriptionData)

          if (this.subscriptionData.deleted_at != null) {
            this.delUser = false
          } else {
            this.delUser = true
          }

          //
          if (this.subscriptionData.coupon != null) {
            this.discount = this.subscriptionData.coupon.percentage
          } else {
            this.discount = '-'
          }

          this.pushVariantsData()
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },

    storeLogger(id) {
      axios
        .post('subscriptions/' + id + '/logger/store', this.loggerNote)
        .then((result) => {
          this.$swal({
            position: 'center',
            icon: 'success',
            title: 'Your work has been saved',
            showConfirmButton: false,
            timer: 1500,
          })
          this.fetchData()
        })
        .catch((err) => {
          this.$swal({
            position: 'center',
            icon: 'error',
            title: 'Error!',
            showConfirmButton: false,
            timer: 1500,
          })
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },

    // delete function to delete area

    deleteSubscription(id) {
      this.$bvModal
        .msgBoxConfirm(
          'Please confirm that you want to delete This subscription.',
          {
            title: 'Are You Sure?',
            size: 'sm',
            okVariant: 'danger',
            okTitle: 'Yes',
            cancelTitle: 'No',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value == true) {
            axios
              .delete('subscriptions/' + id)
              .then((result) => {
                this.$swal({
                  position: 'center',
                  icon: 'success',
                  title: 'Your work has been saved',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.$router.back(1)
              })
              .catch((err) => {
                this.$swal({
                  position: 'center',
                  icon: 'error',
                  title: 'Error!',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.errors_back.length = 0
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    })
                  } else {
                    this.errors_back = err.response.data.data
                  }
                  this.showDismissibleAlert = true
                } else {
                  this.errors_back = []
                  this.errors_back.push({
                    error: 'internal server error',
                  })
                  this.showDismissibleAlert = true
                }
              })
          }
        })
    },

    updateEcontractIds() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          axios
            .post('orders/' + this.id + '/e-contract/update', {
              id_front_image: this.customerData.id_front_image,
              id_back_image: this.customerData.id_back_image,
              customer_id_number: this.customerData.customer_id_number,
              customer_name: this.customerData.customer_name,
            })
            .then((result) => {
              this.$swal({
                position: 'center',
                icon: 'success',
                title: 'Your work has been saved',
                showConfirmButton: false,
                timer: 1500,
              })
              this.subscriptionData.e_contract = result.data.data
              this.enableUpdateCustomerData = false
            })
            .catch((err) => {
              this.$swal({
                position: 'center',
                icon: 'error',
                title: 'Error!',
                showConfirmButton: false,
                timer: 1500,
              })
              this.errors_back.length = 0
              if (err.response.data.data != null) {
                if (this.isString(err.response.data.data)) {
                  this.errors_back.push({
                    error: err.response.data.data,
                  })
                } else {
                  this.errors_back = err.response.data.data
                }
                this.showDismissibleAlert = true
              } else {
                this.errors_back = []
                this.errors_back.push({
                  error: 'internal server error',
                })
                this.showDismissibleAlert = true
              }
            })
        }
      })
    },
    pushVariantsData() {
      if (this.subscriptionData.variants_details.length != 0) {
        for (let cat in this.subscriptionData.variants_details) {
          this.variants.push(
            this.subscriptionData.variants_details[cat].variants
          )
        }
      }
    },

    isString(value) {
      return typeof value === 'string' || value instanceof String
    },
    fileUploadIndex(e) {
      this.orderAttachmentData.file = e.target.files[0]
    },
  },
}
</script>
  
  <style scoped>
/* #card {
      display: flex !important;
      flex-wrap: wrap !important;
  } */
#table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}
#table td,
#table th {
  border: 1px solid #dddddd;
  padding: 8px;
}
#table tr:nth-child(even) {
  background-color: #f5f5f5;
}
.cardH {
  margin-bottom: 0px;
}
.phonenumber {
  font-weight: bolder !important;
  color: #08308B;
  background: none;
}
</style>
  